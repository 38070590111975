import React from "react"
import UnderConstruction from "../components/UnderConstruction"

const New = () => {
    return (
        <>
            <UnderConstruction />
        </>
    )
}
export default New
